@import '../../index-vars.scss';


.comic-update input, .comic-update-smaller input {
  border-radius:10px;
  padding:10px;
  font-size:18px;
  width:400px;
  margin-right:20px;
  margin-bottom:10px;
}

.comic-update select, .comic-update-smaller select {
  border-radius:10px;
  padding:10px;
  font-size:18px;
  margin-right:20px;
  margin-bottom:10px;
}

input.comic-update-smaller , select.comic-update-smaller  {
  width:365px;
  margin-right: 10px;
}

.comic-number-input {
  width: 60px !important;
  margin-left: 10px !important;
}

.comic-price-input {
  width: 90px !important;
  margin-left: 10px !important;
}

.comic-year-input {
  width: 90px !important;
}

.comic-month-input {
  width: 70px !important;
}

.comic-batchname-input select {
  width: auto !important;
  padding-right:20px;
}

.copy-icon {
  position: relative;
  width:16px;
  top:3px;
}

.copy-icon-pulse {
  top: 0px;
  left: -10px;
  position: relative;
  padding:3px;
  border-radius: 20px;
  cursor: pointer;
  border-width:2px;
}

@keyframes pulse {
  from {
    box-shadow:  0 0 0 0 rgba(255,255,255,1),
                 0 0 0 0 rgba(51,51,51,1);
  }
  to {
    box-shadow:  0 0 0 0.65em rgba(255,255,255,1),
                 0 0 0 1em rgba(51,51,51,0);
  }
}

div[class^='*-pulse'] {
  background-color: rgb(51,51,51);
  border-radius: 50%;
  color: white;
  cursor: pointer;
  display: inline-block;
  height: 100px;
  line-height: 100px;
  margin: 1em;
  padding: 1em;
  text-align: center;
  width: 100px;
}

.copy-icon-pulse:active {
  animation: pulse 0.5s 1 ease-out;
}