
// base variables
$yellow-main: #FADC72;
$yellow-darker: #FFB800;
$purple-dark: #6000AB;
$purple-light: #DBD8FB;
$grey-link: #545454;
$blue-link: #0066FF;
$black-mostly: #313131;

// used variables
$button-primary: $yellow-main;
$button-secondary: $yellow-darker;
$main-highlight: $purple-dark;
$header-background: $purple-light;
$header-link-normal: $grey-link;
$link-normal: $blue-link;
$font-normal: $black-mostly;

$tablet-break-width: 877px;