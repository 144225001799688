@import '../../index-vars.scss';

.order-items {
  display: flex;
  flex-direction: row;
}

.order-item-details {
  flex-grow: 1;
  margin-top: auto;
  margin-bottom: auto;
  position: relative;
  left:40px;
}

.order-item-preview-holder {
  width:70px;
  height:100px;
  position: relative;
  overflow: hidden;
  margin-top:10px;
  right:-33px;
  top:-10px;
  flex-shrink: 1;
}


.order-item-preview-cover, .comic-item-preview-cover-game {
  border-radius: 5px;
  width:auto;
  height:100%;
  object-fit: cover;
  z-index: 1;
  position: relative;
}

.comic-item-preview-cover-game {
  
  object-fit: scale-down;
  position: relative;
  right:20px;
}

.order-item-preview:before, .order-item-preview-no-image {
  display:none;
  content: ' ';
  display: inline-block;
  position: relative;
  width:100px;
  height:100px;
  object-fit: cover;
  background-color: white;
  border-radius: 5px;
  background-image: url('https://isleofavalon.com.au/img/noimage.svg');
}