@import '../index-vars.scss';

.search-history-wrapper {
  position: relative;
}

.search-history {
  position: relative;
  border-radius: 10px;
  border: 2px solid #ccc;
  background-color: white;
  z-index: 200;
  top: -20px;
  width: 350px;
  box-shadow: 10px 10px 11px 0px #888888;
}

.search-term {
  position: relative;
  background-color: white;
  padding: 10px;
  padding-left: 15px;
  cursor: pointer;
}

.search-term:hover {
  position: relative;
  background-color: #efefef;
}

.search-header,
.search-header-2 {
  background-color: grey;
  color: white;
  text-transform: uppercase;
  font-size: 10pt;
  padding: 3px;
  padding-left: 15px;
  padding-bottom: 4px;
  border-radius: 8px 8px 0px 0px;
}

.search-header-2 {
  border-radius: 0px;
}

.clear-search-header {
  position: relative;
  left:235px;
  color:$link-normal;
  cursor: pointer;
  font-size:11px;
  top:-1px;
}

.no-searches {
  padding:20px;
  font-style: italic;
}

.icons-wrapper {
  padding: 4px;
  padding-top: 8px;
  height: 70px;
  display: flex;
  flex-direction: row;
}

.search-icon-wrapper {
  display: inline-block;
  position: relative;
  border-radius: 10px;
  width: 56px;
  height: 56px;
  background-color: #ffffff;
  cursor: pointer;
  border: 2px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-icon-wrapper:hover {
  border: 2px solid $button-primary;
}

.search-icon {
  border-radius: 0px;
  padding: 0px;
  max-height: 50px;
  max-width: 50px;
  text-align: center;
  vertical-align: middle;
  margin: auto;
}