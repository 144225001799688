@import "../../index-vars.scss";

.clickable-wrapper {
  position: relative;
  top: -20px;
  left: -20px;
}

.clickable {
  cursor: pointer;
  position: absolute;
  width: 250px;
  height: 400px;
  margin: 10px;
  z-index: 10;
}

.comic-tag-wrapper {
  position: relative;
  left: 45px;
  top: -5px;
  float: right;
  width: 100px;
}

.comic-tag-inner {
  position: absolute;
  width: auto;
}

.comic-tag {
  width: auto;
  position: relative !important;
  display: block;
  padding: 6px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: $main-highlight;
  border-radius: 20px;
  border: 3px #ffffff solid;
  color: #ffffff;
  position: absolute;
  z-index: 8;
  font-weight: bold;
  margin-bottom: 2px;
}

.comic-item-holder,
.comic-item-holder-game {
  width: 210px;
  height: 324px;
  position: relative;
  overflow: hidden;
  margin-left: 10px;
  margin-top: 10px;
}

.comic-item-cover,
.comic-item-cover-bi,
.comic-item-cover-game {
  border-radius: 5px;
  width: auto;
  height: 100%;
  object-fit: cover;
  object-position: center;
  z-index: 1;
  position: relative;
}

.comic-item-cover-bi {
  left: -15px;
}

.comic-item-cover-game {
  height: auto;
  width: 100%;
}

.comic-item-cover:before,
.comic-item-cover-bi:before,
.comic-item-cover-no-image {
  display: none;
  content: ' ';
  display: inline-block;
  /*position: absolute;*/
  width: 210px;
  height: 324px;
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 5px;
  background-color: white;
  border-radius: 5px;
  background-image: url('https://isleofavalon.com.au/img/noimage.svg');
  background-position: center;
}

@media screen and (max-width: $tablet-break-width) {

  .comic-item-cover,
  .comic-item-cover-bi {
    width: 92%;
  }

  .comic-item-cover-bi {
    left: 0px;
  }
}

.comic-item-name {
  padding: 0px;
  margin: 0px;
  color: $font-normal;
  padding-top: 3px;
  margin-left: 10px;
}

.comic-item-company-name {
  color: $font-normal;
}

.comic-item-price,
.comic-item-price-ordered {
  display: inline;
  font-weight: bold;
  color: $header-link-normal;
  left: 12px;
  position: relative;
}
@media screen and (max-width: $tablet-break-width) {
  .comic-item-price {
    top:-3px;
  }
}

.comic-item-price .big-price {
  position: relative;
  font-size: 20pt;
}

.comic-item-price .little-price {
  position: relative;
  top: -9px;
  font-size: 10pt;
}

.comic-item-price-ordered {
  position: relative;
  left: 0px;
}

.comic-item-price-ordered .big-price {
  position: relative;
  font-size: 16pt;
  top: 6px;
}

.comic-item-price-ordered .little-price {
  position: relative;
  top: 0px;
  font-size: 10pt;
}

.purchase-icon {
  float: right;
  border-radius: 1000px;
  height: 46px;
  width: 46px;
  position: relative;
  top: 3px;
}

.comic-item-purchase-button,
.comic-item-non-purchase-button {
  height: 46px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 2px;
  margin-left: 2px;
  margin-right: 2px;
  border-radius: 25px;
  margin-bottom: 5px;
}

.comic-item-purchase-button {
  background-color: #EEEEEE;
  border: 0px solid #EEEEEE;
  position: relative;
  cursor: pointer;
}

.comic-item-purchase-button:hover {
  background-color: $button-primary;
  border: 0px solid $button-primary;
}

.comic-item-purchase-button:active {
  background-color: $button-secondary;
  border: 0px solid $button-secondary;
}


.comic-sold-out {
  position: relative;
  float: right;
  margin-left: 10px;
  top: 8px;
  left: -2px;
  font-size: 10pt;
  font-weight: bold;
  color: $main-highlight;
}
@media screen and (max-width: $tablet-break-width) {
  .comic-sold-out {
    position: relative;
    font-size: 8pt;
    top: 12px;
    padding-bottom: 12px;
  }
}

.comic-name-wrapper {
  min-height: 63px;
}

.type-pill {
  position: fixed;
  float: right;
  background: #8F85FC;
  border-radius: 10px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 8px;
  padding-bottom: 6px;
  display: block;
  width: auto;
  margin-left: 202px;
  margin-top: 20px;
  z-index: 10;
  /* PRE-ORDER */
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  /* identical to box height */

  color: #FFFFFF;
}