@import '../../index-vars.scss';

.add-image-button {
  position:relative;
  float:right;
}
.clear-images-link {
  position:relative;
  float:right;
}

.admin-preview {
  width:125px;
  height:125px;
}

.add-back-issue-preview {
  width:200px;
  height:200px;
}

.comic-item-preview-add-image-container {
  border-top:1px solid grey;
  padding-bottom:20px;
}

.comic-item-preview-add-image-field {
  padding-top:20px;
  display:inline-block;
}


.filedrop {
  border: 3px solid #d3d3d3;
  width:100%;
}
.single-add-item {  
  height: 100%;
}
.single-add-item .filedrop {
  height: 100%;
}

.filedrop.drag {
  border: 3px dashed #1e90ff;
}

.filedrop.ready {
  border: 3px solid white;
}