@import '../../index-vars.scss';

.comic-item-preview-holder {
  width:70px;
  height:100px;
  position: relative;
  overflow: hidden;
  float:right;
  margin-top:10px;
  right:-4px;
  top:-10px;
}

.comic-item-preview-cover, .comic-item-preview-lge-cover, .comic-item-preview-sml-cover, .comic-item-preview-cover-game {
  border-radius: 5px;
  width:auto;
  height:100%;
  object-fit: cover;
  z-index: 1;
  position: relative;
}

.comic-item-preview-lge-cover {
  height:350px;
}

.comic-item-preview-sml-cover {
  top:10px;
  height:80px;
}

.comic-item-preview-cover-game {
  
  object-fit: scale-down;
  position: relative;
  right:20px;
}

.comic-item-preview:before, .comic-item-preview-no-image {
  display:none;
  content: ' ';
  display: inline-block;
  position: relative;
  width:100px;
  height:100px;
  object-fit: cover;
  background-color: white;
  border-radius: 5px;
  background-image: url('https://isleofavalon.com.au/img/noimage.svg');
}


.comic-delete-button-wrapper {
  position: relative;
  float: right;
}

.comic-delete-button {
  position: absolute;
  left: 47px;
  top: -8px;
  z-index: 20;
  border: 5px solid white;
  width: 30px;
  height: 30px;
  border-radius: 100px;
}


.highlight-order-item {
  animation: highlight 2s;
}

@keyframes highlight {
  0% {
    border-color: $button-primary;
  }
  100% {
    border-color: white;
  }
}