@import '../../index-vars.scss';

.no-suggestions {
    color: #999;
    padding: 0.5rem;
}

.suggestions-anchor {
    position: relative;
    top:-9px;
}

.suggestions {
    position: absolute;
    border: 1px solid #999;
    
    list-style: none;
    margin-top: 0;
    max-height: 340px;
    overflow-y: auto;
    padding-left: 0;
    width: calc(300px); 
    background-color: white;
}

.suggestions .suggestion-item {
    padding: 5px;
}

.suggestion-active,
.suggestions .suggestion-item:hover {
    background-color: #008f68;
    color: #fae042;
    cursor: pointer;
    font-weight: 700;
}

.suggestions .suggestion-item:not(:last-of-type) {
    border-bottom: 1px solid #999;
}