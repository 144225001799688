@import '../../index-vars.scss';

.comic-preview {
  position:absolute;
  z-index:200;

  border: 2px solid $link-normal;
  border-radius: 10px;
  background-color: #D8E7FB;
  padding:15px;
  display: flex;
  flex-direction: row;
  width:800px;
  padding-bottom: 0px;
  height:600px;
}

.comic-preview-image {
  width:300px;
}

.comic-preview-holder {
  width:380px;
  height:565px;
  position: relative;
  overflow: hidden;
  padding:10px;
}

.comic-preview-cover, .comic-preview-cover-bi, .comic-item-cover-game {
  border-radius: 5px;
  width:auto;
  height:100%;
  object-fit: cover;
  object-position: center;
  z-index: 1;
  position: relative;
}

.comic-preview-cover-bi {
  left:-20px;
}

.comic-item-cover-game {
  height:auto;
  width:100%;
}


.comic-preview-cover:before, comic-preview-cover-bi:before, .comic-preview-cover-no-image {
  display:none;
  content: ' ';
  display: inline-block;
  /*position: absolute;*/
  width:300px;
  height:465px;
  margin-left:10px;
  margin-top:10px;
  margin-bottom:5px;
  background-color: white;
  border-radius: 5px;
  background-image: url('https://isleofavalon.com.au/img/noimage.svg');
  background-position: center;
}

.comic-preview-image-column {
  flex-direction: column;
  width:50%;
}

.comic-preview-column {
  display: flex;
  flex-direction: column;
  width:50%;
  margin:10px;
  margin-left:20px;
}

/* .comic-preview-titles {
  min-height:100px;
} */

.comic-preview-title {
 font-size: 20pt;
 text-transform: uppercase;
 font-weight: bold;
 color: $font-normal;
}


.comic-preview-subtitle {
  font-size: 18pt;
  text-transform: uppercase;
  font-weight: bold;
  color: $font-normal;
  padding-bottom:15px;  
}

/* .comic-preview-info {
  min-height:20px;
} */

.comic-preview-publisher-link {
  text-align: left;
  padding-bottom:5px;
  color: $link-normal;
  cursor: pointer;
}
.comic-preview-publisher {
  text-align: left;
  padding-bottom:5px;
}
.comic-preview-release-date {
  position: relative;
  float: right;
  text-transform: uppercase;
  font-size:11pt;
}

.comic-preview-blurb {
  flex-grow: 1;
  margin-top:10px;
  overflow-y:auto;
}

.comic-preview-ebay {
  flex-grow: 0;
  margin-top:10px;
  overflow-y:auto;
  border:1px solid #5f5f5f;
  border-radius: 3px;
  padding:5px;
}

.comic-preview-purchase-area {
  height:80px;
  padding-top:20px;
}

.comic-preview-purchase-button, .comic-preview-non-purchase-button {
  height:46px;
  padding-bottom:8px;
  padding-left:12px;
  margin-left:2px;
  margin-right:2px;
  border-radius: 25px;
  margin-bottom:5px;
}
.comic-preview-purchase-button {
  border:0px solid #EEEEEE;
  position: relative;
  cursor: pointer;
}
.comic-preview-purchase-button:hover {
  background-color: $button-primary;
  border:0px solid $button-primary;
}
.comic-preview-purchase-button:active {
  background-color: $button-secondary;
  border:0px solid $button-secondary;
}

.comic-preview-item-price {
  position: relative;
  display: inline-block;
  font-weight:900;
  color:$header-link-normal;
  padding-left:3px;
}

.comic-preview-item-price .big-price {
  position: relative;
  font-size: 22pt;
  top: 6px;
}

.comic-preview-item-price .little-price {
  position: relative;
  top: -5px;
  font-size: 11pt;
}

.comic-preview-purchase-icon-image {
  height:30px;
  width:30px;
  position: relative;
  left:5px;
}

.comic-preview-purchase-icon {
  float:right;
  height:58px;
  width:58px;
  padding-top:8px;
}

.comic-preview-sold-out {
  float:right;
  position: relative;
  top:12px;
  font-size:14pt;
  font-weight:bold;
  color: $main-highlight;
}

.comic-preview-search-terms {
  font-size:10pt;
}
.comic-preview-search-term-label {
  text-transform:uppercase;
}
.comic-preview-search-term {
  display: inline-block;
  border-radius: 20px;
  background-color: whitesmoke;
  padding:5px;
  padding-left:10px;
  padding-right:10px;
  margin-top:5px;
  margin-left:5px;
  font-weight: 700;
  font-size:11pt;
  border: 1px solid whitesmoke;
  cursor: pointer;
  color: $link-normal;
}
.comic-preview-search-term:hover, .comic-preview-search-term:active {
  border: 1px solid $link-normal;
}