@import '../../index-vars.scss';

.admin-navigation {
  margin:30px;
}

.admin-link {
  margin-bottom:10px;
}

.admin-title {
  text-transform: uppercase;
  font-size: 11pt;
  font-weight: bold;
  padding-top:10px;
  padding-bottom:5px;
}

input[type=text].batch-quantity-input  {
  width:40px;
  margin:0px;
  margin-left:10px;
  margin-right:15px;
  display: inline;
}

.batch-name-input {
  width:auto;
  display: inline;
  margin:0px;
}

.add-item-icon {
  position: relative;
  top:4px;
  left:6px;
}