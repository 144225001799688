@import '../../index-vars.scss';


.modal-flex {
  position: absolute;
  top:0px;
  left:0px;
  width:calc(100vw);
  height:calc(100vh);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content:center;
}

.dialog {
  position: relative;
  width: 450px;
  z-index: 200;
  opacity: 1 !important;
  opacity: initial;
  border: 1px solid $link-normal;
  background-color: white;
  border-radius: 10px;
  z-index: 200;
  padding: 20px;
}


.centerpoint {
  top: 50%;
  left: 50%;
  position: absolute;
}

.grey-background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 100;
  opacity: .5;
}

.modal-payment {
  opacity: 1 !important;
  opacity: initial;
  position: fixed;
  border: 1px solid $link-normal;

  width: 450px;
  margin-left: calc(50vw - 225px);
  /* height:550px; */
  margin-top: -350px;

  background-color: white;
  border-radius: 10px;
  z-index: 200;
  left: 20px;
  padding: 20px;
}

@media screen and (max-height:750px) {
  .modal-payment {
    margin-top: -300px;
  }
}

@media screen and (max-height:550px) {
  .modal-payment {
    margin-top: -400px;
  }
}

@media screen and (max-height:350px) {
  .modal-payment {
    margin-top: -450px;
  }
}


@media screen and (max-width: 480px) {
  .modal-payment {
    position: relative;
    max-width: 280px;
    margin-left: -160px;
    top: 25px;
    margin-top: -350px;
  }
}

.dialog-close-button {
  padding-right: 20px;
  float: right;
  position: relative;
}

.payment-dialog-button-container {
  text-align: center;
}

.already-account {
  float: right;
  font-size: 10pt;
  color: #545458;
}

.already-account .link-button {
  font-size: 10pt;
}

.nameContainer {
  display: flex;
  flex-direction: row;
}

.givenNameContainer {
  padding-right: 5px;
  flex-grow: 1;
}

.familyNameContainer {
  padding-left: 5px;
  flex-grow: 1;
}

.addressDetailsContainer {
  display: flex;
  flex-direction: row;
}

.suburbContainer {
  padding-right: 5px;
}

.stateContainer {
  padding-right: 5px;
  padding-left: 5px;
}

.postalCodeContainer {
  padding-left: 5px;
}

.back-link {
  position:relative;
  float:left;
  top:15px;
  text-transform: uppercase;
  font-size:8pt;
  font-weight: bold;
}

.address-summary {
  padding:10px;
  border:1px solid #b6b5b5;
  font-size:10pt;
  border-radius: 5pt;
}

.address-details {
  font-size:11pt;
  padding-top:4px;
  padding-bottom:3px;
}

.address-divider {
  padding-bottom:10px;
  margin-top:10px;
  border-top:1px solid #b6b5b5;
}

@media screen and (max-width: 480px) {
  .nameContainer {
    flex-direction: column;
  }

  .givenNameContainer {
    padding-right: 0px;
  }

  .familyNameContainer {
    padding-left: 0px;
  }

  .addressDetailsContainer {
    flex-direction: column;
  }

  .suburbContainer {
    padding-right: 0px;
  }

  .stateContainer {
    padding-right: 0px;
    padding-left: 0px;
  }

  .postalCodeContainer {
    padding-left: 0px;
  }
}