@import '../../index-vars.scss';

.image-viewer-wrapper {
  position: relative !important;
  top: -103px;
  z-index:1;
  width: 100%;
  text-align: center;
}

.image-viewer {
  display: inline-block;
  position: relative !important;
  background-color: black;
  border-radius: 3px;
  width: auto;
  margin-left: auto;
  margin-right: auto;
  height: 105px;
}

.image-viewer-image-wrapper {
  position: relative;
  display: inline-block;
}

.image-viewer-image {
  position: relative;
  border-radius: 3px;
  width: 80px;
  height: auto;
  max-height: 120px;
  border: 2px solid black;
}

.image-viewer-image-selected {
  position: relative;
  border: 2px solid rgb(255, 0, 0);
}