@import '../../index-vars.scss';

/* Rectangle 6 */
.order-title-top-container {
  background-color: white;
  padding-top: 15px;
}

.order-title-top {
  flex-grow: 0;
  max-height: 14px;
  height: 14px;
  border-radius: 10px 0px 0px 0px;
  background-color: $header-background;
  padding: 0px;
  margin: 0px;
  /* border:1px solid black; */
  line-height: 0;
}

.order-title-bottom {
  position: relative;
  top: -5px;
  flex-grow: 0;

  background: $header-background;
  border-radius: 0px 0px 0px 10px;
  text-align: center;

  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 1;

  height: 30px;

  color: $header-link-normal;
}


.order-box-wrapper {
  position: relative;
}

.order-box {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  background-color: #EEEEEE;
  height: calc(100vh - 265px);
}

.order-list {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  /* height: calc(100vh - 465px); */
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 15px;
  margin-bottom: 5px;
}

.order-item {
  background-color: white;
  border-radius: 10px;
  font-size: 11pt;
  font-weight: normal;
  margin: 0px;
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 10px;
  padding: 4px;
  height: 116px;
  border: 4px solid white;
}

.order-item-names {
  min-height:65px;
}

.order-item-name {
  font-size: 11pt;
  font-weight: 500;
  /* border:1px solid black; */
  width: 205px;
}

.order-item-summary {
  font-size: 11pt;
  padding-bottom: 3px;
  float:right;
  position: relative;
  top:8px;
  left:-10px;
}

.order-item-plus-minus {
  float:right;
  position: relative;
  top:10px;
}

.order-item-quantity-x {
  font-size:10pt;
}
.order-item-quantity-number {
  font-size:15pt;
}

.payment-summary {
  flex-grow: 0;
  background-color: $header-background;
  padding: 10px;
}

.payment-button-container {
  flex-grow: 0;
  padding-top: 15px;
  padding-bottom: 0px;
  max-height: 60px;
  text-align: center;
}

.payment-button-container-bottom {
  flex-grow: 0;
  max-height: 14px;
  height: 14px;
  border-radius: 0px 0px 0px 10px;
  background-color: #EEEEEE;
}

.minus-button,
.plus-button {
  border-radius: 100px;
  border: 2px solid $button-primary;
  width: 20px;
  height: 20px;
  margin-right: 3px;
  background-color: $button-primary;
  padding: 0px;
  cursor: pointer;
}

.plus-text, .minus-text {
  position: relative;
  
  font-style: normal;
  font-weight: 1000;
  font-size: 11pt;
  padding:0px;

  top:-3px;

  color: #FFFFFF;text-shadow: 
  -1px -1px 0px #949494,
   0px -1px 0px #949494,
   1px -1px 0px #949494,
  -1px  0px 0px #949494,
   1px  0px 0px #949494,
  -1px  1px 0px #949494,
   0px  1px 0px #949494,
   1px  1px 0px #949494;
}

.minus-text {
  top: -4px;
  font-size: 11pt;
}

.minus-button:hover,
.plus-button:hover {
  border: 2px solid $button-secondary;
}

.minus-button:active,
.plus-button:active {
  border: 2px solid $button-secondary;
  background-color: $button-secondary;
}

.minus-button:disabled,
.plus-button:disabled {
  border: 2px solid lightgrey;
  background-color: lightgrey;
  cursor: default;
}

.big-purchase-icon {
  height: 54px;
  width: 54px;
  position: relative;
  left: -3px;
}

.purchase-info {
  text-transform: uppercase;
  font-size: 9pt;
  line-height: 1.5;
  font-weight: 400;
}

.close-mobile-order-button {
  position: relative;
  float: right;
  left: -36px;
  top: -5px;
}

.close-mobile-order-image {
  height: 30px;
  width: 30px;
}

@media screen and (max-height: 300px) {
  .purchase-info-wrapper {
    display: none;
  }

  .payment-summary-extra {
    display: none;
  }
}
