@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

@import './index-vars.scss';


/* layout */
* {
  box-sizing: border-box; 
}
body {
  margin: 0;
  height: 100vh;
  overflow: hidden;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  color: $font-normal;
}
#main {
  display: flex;
  min-height: calc(100vh - 40vh);
  height: 100%;
}
#main > article {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  max-height:calc(100vh - 235px);
}
#main > nav, 
#main > aside {
  flex: 0 0 20vw;
  /* background: beige; */
  max-height:calc(100vh - 235px);
  min-width:280px;
  overflow-y: auto;
}
@media screen and (max-width: $tablet-break-width) {
  aside {
    position: fixed;
    z-index: 50;
  }
  /* order list */
  #main > aside.hide-in-tablet {
    max-width:0px;
    min-width:0px;
    padding-left:30px;
  }
}

#main > nav {
  order: -1;
}
@media screen and (max-width: $tablet-break-width) {
  /* order list */
  #main > nav.hide-in-tablet {
    max-width:0px;
    min-width:0px;
    padding-left:30px;
  }
}
article {
  padding: 1em;
}


#root {
  height: 100%;
}


.clear-background, .clear-grey-background {
  position: fixed;
  height:100%;
  width:100%;
  top:0px;
  left:0px;
  z-index:150;
  opacity: 0.2;
  background-color: black;
  pointer-events: visible;
}
.clear-background {
  opacity: 0.2;
}

/* general */
.alert, .warning {
  border-radius: 10px;
  background-color:red;
  color: white;
  font-weight: bold;
  display: inline-block;
  padding-left:5px;
  padding-right:5px;
  min-width: 20px;
  text-align: center;
}
.warning {
  background-color:grey;
}

.link-button {
  background-color: transparent;
  border: none;
  display: inline;
  margin: 0;
  padding: 0;
  color:$link-normal;
  cursor: pointer;
  font-size:inherit;
  font-family: Roboto, Arial, Helvetica, sans-serif;
}
.link-button:hover {
  color:$link-normal;
  text-decoration: underline;
}
.link-button:disabled {
  color:grey;
  text-decoration: none;
  cursor: default;
}

a {
  color:$link-normal;
  text-decoration: none;
  cursor: pointer;
}
a:hover {
  color:$link-normal;
  text-decoration: underline;
}


/* TODO  header.css */
.model-container {
  position:relative;
  display: inline-block;
}


.mobile-menu-wrapper .modal-login { 
  z-index:300;
  left:173px;
  top:200px;
}

.mobile-menu-wrapper .modal-signup { 
  z-index:300;
  left:173px;
  top:30px;
}

.modal-signup, .modal-login {
  position:absolute;
  border: 1px solid $link-normal;
  border-radius: 10px;
  padding:20px;
  background-color: #D8E7FB;
  margin-top:10px;
  text-align: left;
  width:250px;
  left:-140px;
  z-index: 100;
  top:4px;
}

.modal-signup {
  width:300px;
  left:-170px;
}

.model-button-container {
  text-align: center;
}

/* standard-button */

.standard-button {
  background: $button-primary;
  border-radius: 20px;
  color: $header-link-normal;
  border: 3px solid $button-primary;
  padding-left:45px;
  padding-right:45px;
  padding-top:10px;
  padding-bottom:10px;
  font-weight: bold;
  font-size:16px;
  cursor: pointer;
}

.standard-button:disabled, .standard-button:disabled:active {
  background: lightgrey;
  border: 3px solid lightgrey;
  cursor: default;
}

.standard-button:hover:enabled {
  border: 3px solid $button-secondary;
}

.standard-button:active {
  border: 3px solid $button-secondary;
  background: $button-secondary;
}

@media screen and (max-width: 480px) {
  .standard-button {
    font-size: 11pt;
    padding-left:15px;
    padding-right:15px;
    padding-top:10px;
    padding-bottom:10px;
  }
}

/* form input fields */
Label {
  font-size: 11pt;
  color:#545458;
}


input[type=text], input[type=email], input[type=password], input[type=number], input[type=date], textarea, select {
  background: #fbfbfc;
  padding: 0.5rem;
  border: 1px solid #b6b5b5;
  border-radius: 5px;
  margin: 0.3rem 1rem 20px 0;
  width:100%;
}


input[type=text]:focus-visible, input[type=email]:focus-visible, input[type=password]:focus-visible, input[type=number]:focus-visible, input[type=date]:focus-visible, textarea:focus-visible, select:focus-visible {
  outline: 1px solid #000000;
  border: 1px solid white;
}

input:disabled {
  background: lightgrey;
}

input[type=checkbox] {
  background: #fbfbfc;
  padding: 0.5rem 1rem;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  margin: 0.3rem 1rem 20px 0;
  height: 25px;
  width: 25px;
}

textarea {
  min-height: 80px;
}

@media screen and (max-width: 480px), screen and (max-height: 750px) {
  label {
    font-size: 11pt;
  }
  input[type=text], input[type=email], input[type=password] {
    background: #fbfbfc;
    padding: 0.3rem;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    display: block;
    margin: 0.3rem 1rem 10px 0;
  }

  input[type=checkbox] {
    position: relative;
    height: 15px;
    width: 15px;
    top:-3px;
  }
}

.invalid {
  border: 1px solid red !important;
}

.checkbox-label {
  position: relative;
  top:-5px;
}

*::-webkit-scrollbar,
*::-webkit-scrollbar-thumb {
  width: 26px;
  border-radius: 13px;
  background-clip: padding-box;
  border: 10px solid transparent;
  color: rgba(0, 0, 0, 0);
  transition: color .3s ease;
  min-height: 40px;
}

*::-webkit-scrollbar-thumb {        
  box-shadow: inset 0 0 0 10px;
  transition: color .3s ease;
  min-height: 40px;
}

*:hover::-webkit-scrollbar-thumb {
  color: rgba(0, 0, 0, 0.3);
  transition: color .3s ease;
  min-height: 40px;
}

.social-container {
  max-width: 300px;
  padding:20px;
}

.social-login-wrapper {
  -webkit-font-smoothing: antialiased;
  color: $font-normal;
  cursor: pointer;
  font-size: 19px;
  user-select: none;
  align-items: center;
  display: flex;
  height: 100%;
}

.social-login-svg {
  -webkit-font-smoothing: antialiased;
  color: $font-normal;
  cursor: pointer;
  font-size: 19px;
  user-select: none;
  display: flex;
  justify-content: center;
  min-width: 26px;
}

.social-login-buffer {
  -webkit-font-smoothing: antialiased;
  color: $font-normal;
  cursor: pointer;
  font-size: 19px;
  user-select: none;
  width: 10px;
}

.social-login-label-facebook, .social-login-label-google {
  -webkit-font-smoothing: antialiased;
  color: $font-normal;
  cursor: pointer;
  font-size: 19px;
  user-select: none;
  text-align: left;
  width: 100%;
}
.social-login-label-facebook{
  color: white;
}

.facebook-button, .google-button {
  -webkit-font-smoothing: antialiased;
  display: block;
  border: 0px;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 1px 2px;
  color: rgb(255, 255, 255);
  cursor: pointer;
  font-size: 19px;
  margin: 5px;
  width: calc(100% - 10px);
  overflow: hidden;
  padding: 0px 10px;
  user-select: none;
  height: 50px;
  background: rgb(59, 89, 152);
}

.google-button {
  background: white;
}


/* standard classes */

.display-none {
  display:none;
}

.display-block {
  display:block;
}

.inline, input[type=text].inline, input[type=email].inline, input[type=password].inline, input[type=number].inline, input[type=date].inline {
  display: inline-block !important;
}

.block {
  display: block;
}

.centre {
  text-align: center;
}

.image-button {
  padding:0px;
  margin:0px;
  width:0px;
  height:0px;
  border:0px;
  cursor: pointer;
}

.right {
  float:right;
}

.left {
  float:left;
}

.strikethrough {
  text-decoration: line-through;
}

.no-top-margin {
  margin-top:0px;
}
.no-margin {
  margin:0px;
}
.no-bottom-margin {
  margin-bottom: 0px !important;
}
.bottom-margin-small {
  margin-bottom: 5px !important;
}

input::placeholder {
  color:rgb(163, 163, 163);
}

.uppercase {
  text-transform: uppercase;
}
.lowercase {
  text-transform: lowercase;
}

.thin-padding {
  padding: 0.25rem;
}

.one-up {
  position: relative;
  top:-1px;
}
.two-up {
  position: relative;
  top:-2px;
}

.one-down {
  position: relative;
  top:1px;
}
.two-down {
  position: relative;
  top:2px;
}

.bold {
  font-weight: bold;
}

.small {
  font-size: 80%;
}

.padding-top-large {
  padding-top:10px;
}

.padding-top-medium {
  padding-top:5px;
}

.padding-top-small {
  padding-top:3px;
}

.margin-bottom-less {
  margin-bottom: 10px;
}

.margin-bottom-medium {
  margin-bottom: 5px;
}

.auto-width {
  width: auto !important;
}

.down-10, .top-10 {
  position: relative;
  top:10px;
}
.down-15, .top-15 {
  position: relative;
  top:15px;
}
.down-25, .top-25 {
  position: relative;
  top:25px;
}
.down-30, .top-30 {
  position: relative;
  top:30px;
}

.line-through {
  text-decoration: line-through;
}

.fade {
  opacity: 50%;
}