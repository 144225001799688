.reset-password-form {
  max-width: 400px;
}

.reset-password {
  font-size:10pt;
}

.reset-password-icon {
  position: relative;
  top:6px;
  margin-right:10px;
}

ul.no-points > li {
  position: relative;
  list-style:none;
  right:20px;
}