.account-navigation {
  margin:30px;
}

.link {
  margin-bottom:10px;
}

.account-title {
  text-transform: uppercase;
  font-size: 11pt;
  font-weight: bold;
  padding-top:10px;
  padding-bottom:5px;
}