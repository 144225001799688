@import '../../index-vars.scss';

.comic-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.comic-item {
  display: flex;
  background-color: #EEEEEE;
  border-radius: 10px;
  flex-grow: 0;
  width:250px;
  margin:10px;
  min-height:400px;
  padding:10px;
  flex-direction: column;
  padding-bottom: 5px;
}

h1, h4 {
  margin:10px;
  margin-left:0px;
  padding-left:0px;
}
.link-small {
  font-size: 12pt;
}