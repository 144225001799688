@import '../index-vars.scss';

/* menu */
.header-top-menu {
  display: flex;
  flex-wrap: wrap;
  height: 64px;
  width: calc(100vw - 220px);
  /* border:1px solid black; */
}

.header-menu-left {
  flex-grow: 1;
  text-align: center;
}

.header-menu-centre {
  flex-grow: 1;
  text-align: center;
  padding-top: 7px;
}

.header-menu-link {
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  text-decoration: none;
  border-bottom: 4px solid $header-background;
  position: relative;
  top: 5px;
  color: $header-link-normal;
}

.header-menu-link:hover {
  color: $main-highlight;
  border-bottom: 4px solid $main-highlight;
  text-decoration: none;
}

.header-menu-bullet {
  position: relative;
  top: 4px;
  font-size: 24px;
}

.header-menu-sm-bullet {
  font-size: 12px;
}

.header-menu-centre-links {
  top: 2px;
  position: relative;
}

.header-menu-right {
  flex-grow: 1;
  text-align: right;
  vertical-align: middle;
}

.header-menu-right-profile {
  padding-top: 21px;
  padding-right: 30px;
}

.header-menu-centre .link-button {
  font-size: 12pt;
}

.header-menu-right-signup-login {
  padding-top: 17px;
  padding-right: 40px;
}

@media screen and (max-width: 1290px) {
  .header-menu-centre {
    top: 4px;
    position: relative;
  }

  .header-menu-link {
    font-size: 24px;
  }

  .header-menu-bullet {
    font-size: 18px;
  }

  .header-menu-sm-bullet {
    font-size: 10px;
    top: 1px;
    position: relative;
  }

  .header-menu-right-profile {
    padding-top: 20px;
  }

  .header-menu-centre .link-button,
  .header-menu-centre-links a,
  .header-menu-right-profile .link-button {
    font-size: 14px;
    top: 1px;
    position: relative;
  }
}


@media screen and (max-width: 1170px) {
  .header-menu-centre {
    top: 8px;
  }

  .header-menu-left {
    max-width: 0px;
  }

  .header-menu-right-signup-login {
    padding-right: 0px;
  }

  .header-menu-link {
    font-size: 18px;
  }

  .header-menu-bullet {
    font-size: 12px;
  }

  .header-menu-centre .link-button,
  .header-menu-centre-links a,
  .header-menu-right-profile .link-button {
    top: 2px;
  }
}

@media screen and (max-width: 990px) {
  .header-menu-centre-links {
    display: none;
  }
}

.everything-filter {
  opacity: 0;
  background-color: black;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 1px;
  left: 1px;
  z-index: 50;
}

.logout {
  font-size: 12px;
}

/* 
.login-signup-link {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;

  color: $header-link-normal;
  border: 0px;
  background-color: inherit;
  padding: 0px;
  margin: 0px;
  cursor: pointer;
}

.login-signup-link:hover {
  color: $main-highlight;
} */

h3 {
  padding-top: 0px;
  margin-top: 0px;
}

/* search */

.search-wrapper {
  position: absolute;
  margin-left: calc(50% - 100px);
  top: 110px;
  z-index: 10;
}

input[type=text].search-input {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;

  color: #929090;

  box-sizing: border-box;

  background: #FFFFFF;
  border: 1px solid #FFFFFF;
  border-radius: 10px;
  padding-left: 42px;
  padding-right: 8px;
  padding-top: 9px;
  height: 40px;
  width: 350px;
}

.search-image {
  position: absolute;
  left: 10px;
  top: 11px;
  cursor: pointer;
  z-index: 30;
}

.clear-search-image {
  position: relative;
  cursor: pointer;
  float: right;
  left: -30px;
  top: -50px;
  display: none;
}

.search-input:valid:hover+.clear-search-image {
  display: block;
}

.clear-search-image:hover {
  display: block;
}

.header-big-image {
  text-align: right;
  width: 100%;
  display: inline-block;
}

/* top banner */

.banner {
  display: flex;
}

.big-images {
  display: flex;
}

.banner-menu {
  display: flex;
  flex-direction: column;
}

.image-flip {
  transform: scaleX(-1);
}


.header-logo {
  display: inline-block;
  padding-left: 40px;
  padding-right: 27px;
  padding-top: 10px;
  padding-bottom: 0px;
  margin: 0px;
}

.logo-link,
.logo-link:hover {
  text-decoration: none;
}

.ioa-titles {
  top: -10px;
  position: relative;
  left: 5px;
}

.ioa-main-title {

  text-align: center;
  width: 215px;

  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;

  color: $main-highlight;
  text-transform: uppercase;

  text-shadow: 0 0 8px white, 0 0 8px white, 0 0 8px white, 0 0 8px white;
}

.ioa-sub-title {

  text-align: center;
  width: 215px;

  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  padding-top: 3px;

  text-align: center;

  color: $main-highlight;

  text-shadow: 0 0 8px white, 0 0 8px white, 0 0 8px white, 0 0 8px white;
}

.logout .link-button {
  font-size: 10pt;
}

.login-signup {
  position: relative;
  top: -7px;
  margin-left: 10px;

  padding-left: 25px;
  padding-right: 25px;
  padding-top: 8px;
  padding-bottom: 8px;
}


@media screen and (max-width: 990px) {
  .login-signup {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.banner {
  background: $header-background;
  height: 235px;
  color: $header-link-normal;
}

.mobile-banner {
  display: none;
  background: $header-background;
  height: 50px;
  color: $header-link-normal;
  width: calc(100vw);
  z-index: 10;
}

.show-mobile-order-button {
  float: right;
  background-color: $header-background;
  border: 0px solid $header-background;
  position: relative;
  left: -5px;
  top: 8px;
  cursor: pointer;
}

.show-mobile-order-image {
  height: 30px;
  width: 30px;
}

.show-mobile-menu-button {
  float: left;
  background-color: $header-background;
  border: 0px solid $header-background;
  position: relative;
  left: 5px;
  top: 11px;
  cursor: pointer;
}

.show-mobile-menu-image {
  height: 24px;
  width: 24px;
}


.mobile-menu,
.profile-menu {
  opacity: 1 !important;
  opacity: initial;
  position: absolute;
  border: 1px solid $link-normal;

  width: 200px;
  left: 10px;
  top: 50px;

  background-color: white;
  border-radius: 10px;
  z-index: 300;
  padding: 20px;
}


.mobile-menu-item,
.profile-menu-item {
  display: block;
  background: $button-primary;
  border-radius: 5px;
  color: $header-link-normal;
  border: 3px solid $button-primary;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  width: 160px;
  text-decoration: none;
}

.profile-menu-wrapper {
  display: inline-block;
  position: relative;
}

.profile-menu {
  top: 10px;
  left: -105px;
  z-index: 60;
  text-align: left;
}

.mobile-menu-item:last-child,
.profile-menu-item:last-child {
  margin-bottom: 0px;
}

.mobile-menu-item:disabled,
.profile-menu-item:disabled {
  background: lightgrey;
  border: 3px solid lightgrey;
  cursor: default;
}

.mobile-menu-item:hover:enabled,
.profile-menu-item:hover:enabled {
  border: 3px solid $button-secondary;
}

.mobile-menu-item:active,
.profile-menu-item:active {
  border: 3px solid $button-secondary;
  background: $button-secondary;
}

.mobile-menu-item:hover,
.profile-menu-item:hover {
  color: $header-link-normal;
  text-decoration: none;
}

.warning-banner {
  background-color: $button-primary;
  border: 2px solid $button-secondary;
  padding: 10px;
  text-align: center;
  position: absolute;
  top: 35px;
  width: 90%;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 100;
}
@media screen and (max-width: $tablet-break-width) {
  .warning-banner {
    display: none;
  }
}


@media screen and (max-width: $tablet-break-width) {
  body {
    font-size: 15px;
  }

  .banner {
    display: none;
  }

  .mobile-banner {
    position: absolute;
    display: block;
    z-index: 10;
  }

  .search-wrapper {
    top: 5px;
    z-index: 20;
    margin-left: 50px;
  }

  .search-input {
    width: 250px;
    top: -5px;
    position: relative;
  }

  /* aside {
    display: none;
  } */
  #main>article {
    max-height: calc(100vh);
    padding: 0px;
    margin: 0px;
  }

  *::-webkit-scrollbar,
  *::-webkit-scrollbar-thumb {
    width: 0px;
  }

  .comic-item {
    width: calc(25% - 20px);
    padding: 5px;
    min-height: auto;
    min-width: 200px;
  }

  .comic-item-holder {
    height: auto;
    width: auto;
    max-width: 250px;
    max-height: 510px;
  }

  .comic-name-wrapper {
    min-height: 42px;
  }

  header {
    position: relative;
    background: $header-background;
  }

  #main {
    height: auto;
  }

  #main>article {
    overflow-y: hidden;
    height: 100%;
    max-height: 100%;
    display: block;
    margin-top: 50px;
  }

  h1 {
    margin-left: 15px;
    margin-bottom: 0px;
  }

  h4 {
    margin-left: 15px;
  }

  body {
    overflow-y: auto;
  }

  .clickable-wrapper {
    display: none;
  }

  .comic-item-price .big-price {
    position: relative;
    font-size: 15pt;
    top: 6px;
  }

  .comic-item-price .little-price {
    position: relative;
    top: 0px;
    font-size: 9pt;
  }

  .order-box-wrapper {
    display: none;
    position: fixed;
    top: 1px;
    left: calc(50vw - 150px);
    z-index: 100;
  }

  .mobile-order-list .order-box-wrapper {
    display: block;
  }

  .order-box {
    position: relative;
    top: 1px;
    padding: 10px;
    border-radius: 20px;
    height: calc(95vh);
    padding-top: 0px;
    min-width: 300px;
    box-shadow: 0px 20px 20px 20px #888888;
  }

  .order-title-top-container {
    background-color: inherit;
  }

  .payment-button-container-bottom {
    background-color: inherit;
  }

  .order-title-bottom {
    padding-top: 10px;
    border-radius: 20px;
    padding-bottom: 30px;
  }

  .order-title-top {
    display: none;
  }

  .search-image {
    top: 6px;
  }

  .clear-search-image {
    top: -55px;
  }
}



@media screen and (max-width: 480px) {
  body {
    font-size: 14px;
  }

  .comic-item {
    width: calc(50% - 20px);
    height: auto;
    padding: 5px;
    min-height: auto;
  }

  .comic-item-holder {
    height: auto;
    width: auto;
    max-width: 334px;
    max-height: 510px;
  }

  .comic-name-wrapper {
    z-index: 10;
  }

  #main {
    min-height: calc(100vh - 40vh);
  }

  #main>article {
    overflow-y: auto;
    height: auto;
    max-height: calc(100vh);
    display: block;
  }

  body {
    overflow-y: hidden;
  }

  .clickable-wrapper {
    display: none;
  }

  .search-input {
    width: 180px;
  }
}

@media screen and (max-width: 480px),
screen and (max-height: 750px) {
  .clear-search-image {
    top: -40px;
  }
  .search-history-wrapper {
    top: 10px;
  }
}